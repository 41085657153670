import '../RegisterCard/RegisterCard.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import RestService from "../../../services/rest.service";
import EndpointConstants from '../../../constants/Endpoint.constants';
const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  middleName: yup.string(),
  lastName: yup.string().required('Last Name is required'),
  dob: yup.date().required('Date of Birth is required'),
  gender: yup.string().required('Gender is required'),
  age: yup.number().required('Age is required').positive().integer(),
  photo: yup.mixed().required('Patient Photo is required'),
  guardianName: yup.string().required('Guardian Name is required'),
  guardianOccupation: yup.string().required('Guardian Occupation is required'),
  address1: yup.string().required('Address 1 is required'),
  address2: yup.string().required('Address 2 is required'),
  state: yup.string().required().default('Andhra Pradesh'),
  district: yup.string().required('District is required'),
  pinCode: yup.string().matches(/^\d{6}$/, 'Invalid PIN code').required(),
  doorNo: yup.string().required('Door No. is required'),
  street: yup.string().required('Street is required'),
  villageTown: yup.string().required('Village/Town is required'),
  disease: yup.string().required('Disease is required'),
  doctorHospital: yup.string(),
  emergencyContact: yup.string().required('Emergency Contact is required'),
  email: yup.string().email('Invalid email'),
  phone: yup.string().required('Phone Number is required'),
  documents: yup.mixed().required('Documents are required'),
  conditionPhoto: yup.mixed().required('Condition Photo is required'),
  sadaramCertificate: yup.mixed(),
  aadharCard: yup.mixed().required('Aadhar Card is required'),
  udidCard: yup.mixed(),
  pensionReceiving: yup.string().required('Pension Receiving is required'),
  geneticTest: yup.string().required('Genetic Test is required'),
  wheelchairReceived: yup.string().required('Wheelchair status is required'),
  remarks: yup.string(),
  declaration: yup.boolean().oneOf([true], 'Declaration is required'),
});

const RegistrationForm = () => {
  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      state: 'Andhra Pradesh'
    }
  });
  const restService=new RestService();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState({});
  const [requiredPaymentGateway, setRequiredPaymentGateway] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      setFiles(prev => ({ ...prev, [fieldName]: file }));
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const formData = new FormData();
      
      // Append all files
      Object.entries(files).forEach(([key, file]) => {
        formData.append(key, file);
      });

      // Append other form data
      Object.entries(data).forEach(([key, value]) => {
        if (key !== 'declaration') {
          formData.append(key, value);
        }
      });
	  formData.append("registrationSource", "USER_PORTAL")
	  restService.doPost(EndpointConstants.ADD_PATIENTS, formData).then(({data})=>{
		setLoading(false);
		let url=data.paymentUrl.instrumentResponse.redirectInfo.url
		window.location.href=url;
		console.log(data);
	  })
    //   await axios.post('https://ardo.org.in/arapis/api/user/register', formData);
    //   toast.success('Registration successful!');
    //   navigate('/login');
	  
    } catch (error) {
      toast.error(error.response?.data.message || 'Registration failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='login-box'>
      <div className='container'>
        <h1>Patient Registration Form</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row'>
            {/* Personal Information */}
            <div className="col-md-6">
              <div className='input-box'>
                <label>First Name*</label>
                <input {...register('firstName')} />
                {errors.firstName && <p className='error'>{errors.firstName.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>Middle Name</label>
                <input {...register('middleName')} />
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>Last Name*</label>
                <input {...register('lastName')} />
                {errors.lastName && <p className='error'>{errors.lastName.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>Date of Birth*</label>
                <input type="date" {...register('dob')} />
                {errors.dob && <p className='error'>{errors.dob.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>Gender*</label>
                <select {...register('gender')}>
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                {errors.gender && <p className='error'>{errors.gender.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>Age*</label>
                <input type="number" {...register('age')} />
                {errors.age && <p className='error'>{errors.age.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box-file'>
                <label>Upload Patient Photo*</label>
                <input 
                  type="file" 
                  className='form-control'
                  {...register('photo')}
                  onChange={(e) => handleFileChange(e, 'photo')}
                />
                {errors.photo && <p className='error'>{errors.photo.message}</p>}
              </div>
            </div>

            {/* Guardian Information */}
            <div className="col-md-6">
              <div className='input-box'>
                <label>Guardian Name*</label>
                <input {...register('guardianName')} />
                {errors.guardianName && <p className='error'>{errors.guardianName.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>Guardian Occupation*</label>
                <input {...register('guardianOccupation')} />
                {errors.guardianOccupation && <p className='error'>{errors.guardianOccupation.message}</p>}
              </div>
            </div>

            {/* Address Information */}
            <div className="col-md-12">
              <div className='input-box'>
                <label>Address 1*</label>
                <textarea {...register('address1')} rows="3" />
                {errors.address1 && <p className='error'>{errors.address1.message}</p>}
              </div>
            </div>

            <div className="col-md-12">
              <div className='input-box'>
                <label>Address 2*</label>
                <textarea {...register('address2')} rows="3" />
                {errors.address2 && <p className='error'>{errors.address2.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>State*</label>
                <input {...register('state')} />
                {errors.state && <p className='error'>{errors.state.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>District*</label>
                <input {...register('district')} />
                {errors.district && <p className='error'>{errors.district.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>PIN Code*</label>
                <input {...register('pinCode')} />
                {errors.pinCode && <p className='error'>{errors.pinCode.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>Door No.*</label>
                <input {...register('doorNo')} />
                {errors.doorNo && <p className='error'>{errors.doorNo.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>Street*</label>
                <input {...register('street')} />
                {errors.street && <p className='error'>{errors.street.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>Village/Town*</label>
                <input {...register('villageTown')} />
                {errors.villageTown && <p className='error'>{errors.villageTown.message}</p>}
              </div>
            </div>

            {/* Medical Information */}
            <div className="col-md-6">
              <div className='input-box'>
                <label>Disease*</label>
                <select {...register('disease')}>
                  <option value="">Select Disease</option>
                  <option value="muscularDystrophy">Muscular Dystrophy</option>
                  <option value="duchenne">Duchenne Muscular Dystrophy</option>
                  <option value="limb_girdle">Limb–Girdle Muscular Dystrophy</option>
                  <option value="facioscapulohumeral">Facioscapulohumeral Muscular Dystrophy</option>
                  <option value="emery_dreifuss">Emery–Dreifuss Muscular Dystrophy</option>
                  <option value="myotonic">Myotonic Dystrophy</option>
                  <option value="oculopharyngeal">Oculopharyngeal Muscular Dystrophy</option>
                  <option value="becker">Becker Muscular Dystrophy</option>
                  <option value="congenital">Congenital Muscular Dystrophy</option>
                  <option value="distal_myopathy">Distal Myopathy</option>
                  <option value="spinal_muscular_atrophy">Spinal Muscular Atrophy</option>
                  <option value="unknown">Unknown</option>
                  <option value="other">Other</option>
                </select>
                {errors.disease && <p className='error'>{errors.disease.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>Consulted Doctor/Hospital</label>
                <input {...register('doctorHospital')} />
              </div>
            </div>

            {/* Contact Information */}
            <div className="col-md-6">
              <div className='input-box'>
                <label>Emergency Contact*</label>
                <input {...register('emergencyContact')} />
                {errors.emergencyContact && <p className='error'>{errors.emergencyContact.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>Email ID</label>
                <input type="email" {...register('email')} />
                {errors.email && <p className='error'>{errors.email.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box'>
                <label>Phone Number*</label>
                <input {...register('phone')} />
                {errors.phone && <p className='error'>{errors.phone.message}</p>}
              </div>
            </div>

            {/* File Uploads */}
            <div className="col-md-6">
              <div className='input-box-file'>
                <label>Upload Documents*</label>
                <input 
                  type="file" 
                  className='form-control'
                  {...register('documents')}
                  onChange={(e) => handleFileChange(e, 'documents')}
                />
                {errors.documents && <p className='error'>{errors.documents.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box-file'>
                <label>Upload Condition Photo*</label>
                <input 
                  type="file" 
                  className='form-control'
                  {...register('conditionPhoto')}
                  onChange={(e) => handleFileChange(e, 'conditionPhoto')}
                />
                {errors.conditionPhoto && <p className='error'>{errors.conditionPhoto.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box-file'>
                <label>Sadaram Certificate</label>
                <input 
                  type="file" 
                  className='form-control'
                  {...register('sadaramCertificate')}
                  onChange={(e) => handleFileChange(e, 'sadaramCertificate')}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box-file'>
                <label>Aadhar Card*</label>
                <input 
                  type="file" 
                  className='form-control'
                  {...register('aadharCard')}
                  onChange={(e) => handleFileChange(e, 'aadharCard')}
                />
                {errors.aadharCard && <p className='error'>{errors.aadharCard.message}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <div className='input-box-file' >
                <label>Upload UDID Card</label>
                <input 
                  type="file" 
				          className="form-control"
                  {...register('udidCard')}
                  onChange={(e) => handleFileChange(e, 'udidCard')}
                />
              </div>
            </div>

            {/* Pension Information */}
            <div className="col-md-6">
              <div className='input-box'>
                <label>Pension Receiving*</label>
                <select {...register('pensionReceiving')}>
                  <option value="">Select Pension Amount</option>
                  <option value="none">None</option>
                  <option value="6000">6000</option>
                  <option value="15000">15000</option>
                  <option value="others">Others</option>
                </select>
                {errors.pensionReceiving && <p className='error'>{errors.pensionReceiving.message}</p>}
              </div>
            </div>

            {/* Genetic Test */}
            <div className="col-md-6">
              <div className='input-box'>
                <label>Genetic Test*</label>
                <select {...register('geneticTest')}>
                  <option value="">Select Genetic Test</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                {errors.geneticTest && <p className='error'>{errors.geneticTest.message}</p>}
              </div>
            </div>

            {/* Wheelchair Received */}
            <div className="col-md-6">
              <div className='input-box'>
                <label>Wheelchair Received*</label>
                <select {...register('wheelchairReceived')}>
                  <option value="">Select Wheelchair Received</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                {errors.wheelchairReceived && <p className='error'>{errors.wheelchairReceived.message}</p>}
              </div>
            </div>

            {/* Remarks */}
            <div className="col-md-12">
              <div className='input-box'>
                <label>Remarks</label>
                <textarea {...register('remarks')} rows="3" />
              </div>
            </div>

            {/* Declaration */}
            <div className="col-md-12 py-2">
              {/* <div className='input-box'> */}
                <label>
                  <input type="checkbox" {...register('declaration')} />&nbsp;
                  I confirm the above details are true to the best of my knowledge. I agree to all the rules and regulations of the Organisation and organisation decision is final.
                </label>
                {errors.declaration && <p className='error'>{errors.declaration.message}</p>}
              {/* </div> */}
            </div>

            {/* Payment Gateway */}
            {/* <div className="col-md-12">
              <label>
                <input 
                  type="checkbox" 
                  checked={requiredPaymentGateway} 
                  onChange={(e) => setRequiredPaymentGateway(e.target.checked)} 
                />
                Payment Gateway
              </label>
            </div> */}

            {/* Submit Button */}
            <div className="col-md-12">
              <button type="submit" className="btn btn-outline-primary" disabled={loading}>
                {loading ? <Spinner size="sm" /> : 'Submit'}
              </button>
            </div>

            {/* <Link to="/login">
              <div className="CreateAccount">Back to Login</div>
            </Link> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;