import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterOne from '../common/footer/FooterOne';
import BlogSectionThree from '../components/blog/BlogSectionThree';


const Activities = () => {


    
    return (
        <>
            <HeaderOne />
            <Breadcrumb
                heading="Activities"
                currentPage="Activities"
            />
            <BlogSectionThree /> 
            <FooterOne />
        </>
    )
}

export default Activities;