import React from 'react';
import HeaderOne from '../common/header/HeaderOne'; 
import FooterOne from '../common/footer/FooterOne';
import Thankyou from '../components/Thankyou/Thankyou';

const ThankyouPage = () => {
	return (
		<>
			<HeaderOne />
			<Thankyou />
			<FooterOne />
		</>
	)
}

export default ThankyouPage;
