import React, { useEffect, useState } from 'react';
import EndpointConstants from '../../constants/Endpoint.constants';
import RestServices from '../../services/rest.service';
import { useNavigate } from 'react-router-dom';



	const BlogSectionThree=()=> {


		const [activities, setActivities]=useState([]);
		const restService=new RestServices();
		const navigate=useNavigate();
		useEffect(()=>{
			restService.doGet(EndpointConstants.GET_EVENTS).then(({ data }) => {
				console.log(data);
				setActivities(data.filter((item)=>item.eventType=="activities"));
				this.doChanges();
			})
		},[])
		return (
			<>
				<section className="blog-three">
					<div className="container">
						<div className="section-title text-center">
							<span className="section-title__tagline">Our Blog Post</span>
							<h2 className="section-title__title">We Best To Largest <br /> Of This Blog.</h2>
						</div>
						<div className="row">
							{activities.reverse().slice(0,3).map(item => {
								console.log(item)
								return (

									<div className="col-xl-4 col-lg-4 wow fadeInUp" onClick={()=>{navigate("/details/"+item.id)}} o data-wow-delay="100ms">

										<div className="blog-three__single">
											<div className="blog-three__img">
												<img src={item.eventImage} alt="" />
											</div>
											<div className="blog-three__content">
												<h3 className="blog-three__title">
												{item.eventName}
												{/* Fa La La La!! Online Cultural Fest 2023 */}
												</h3>
												<div className='divider'></div>
												<div className="blog-three__person">
													<p>
														{/* ARDO welcomes the students of BRIGHT BEGINNINGS to participate in our annual online cultural fest "Fa La La La!!" on 19th December 2023(Tuesday) The competitions include Singing, Drawing, Poster making,Dubsmash, Craft & Art Gifts will be presented to the winners.

														"Winter break holidays from 20th December 2023 - 5th january 2024" */}
														{/* {item.description} */}
														<div dangerouslySetInnerHTML={{ __html: item.summary }}></div>
													</p>
												</div>
											</div>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</section>
			</>
		)
	}


	export default BlogSectionThree;
