import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EndpointConstants from '../../constants/Endpoint.constants';
import RestServices from '../../services/rest.service';

const CausesFour= (props)=> {

	
	const restService = new RestServices();
	const [events, setEvents]=useState([]);
	const navigate=useNavigate();
	
	useEffect(()=>{
		
		const $ = window.$;

		if ($(".count-bar").length) {
			$(".count-bar").appear(
				function () {
					var el = $(this);
					var percent = el.data("percent");
					$(el).css("width", percent).addClass("counted");
				}, {
				accY: -50
			}
			);
		}
		restService.doGet(EndpointConstants.GET_EVENTS).then(({ data }) => {
			console.log("chec",data);
			setEvents( data.filter((item) => item.eventType == "events") );
			//doChanges();
		})
	},[])


	
		return (
			<>
				<section className="causes-one">
					<div className="container">
						<div className="section-title text-center">
							<span className="section-title__tagline">Our Causes Events</span>
							<h2 className="section-title__title">We Popular To Provide <br /> Of Experience.</h2>
						</div>
						<div className="row">
							{events.map((item)=>{
								return (
									<div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms" onClick={()=>navigate(`../details/${item.id}`)}>

								<div className="causes-one__single">
									<div className="causes-one__img">
										<img src={item.eventImage} alt="" />
									</div>
									<div className="causes-one__content-box">
										<div className="causes-one__donate-btn-box">
											<Link to={process.env.PUBLIC_URL + `/donate-now`} className="thm-btn causes-one__donate-btn">Donate Now</Link>
										</div>
										<div className="causes-one__content">
											<h3 className="causes-one__title"> 
												{item.title}
											</h3>

											<div>
												<p className="padding-bottom"  dangerouslySetInnerHTML={{ __html: item.summary }}>
													
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>

								)
							})}
						</div>
					</div>
				</section>
			</>
		)
	
}


export default CausesFour;