import React from 'react';
import RestServices from '../../services/rest.service';
import EndpointConstants from '../../constants/Endpoint.constants';

export default class ContactInfo extends React.Component {

	restservice = new RestServices();
	constructor(props) {
		super(props);
		this.state = {
			siteDetails: null, // Initialize state
		};
	}

	componentDidMount() {
		this.restservice
			.doGet(EndpointConstants.GET_SITE_SETTINGS)
			.then((response) => {
				console.log(response.data);
				if (response && response.data) {
					this.setState({
						siteDetails: response.data,
					});
				}
			})
			.catch((error) => {
				console.error("Error fetching site settings:", error);
			});
	}

	render() {
		const { siteDetails } = this.state;
		return (
			<>
				<section className="contact-info">
					<div className="container">
						<div className="row">
							<div className="col-xl-4 col-lg-4">

								<div className="contact-info__single">
									<h4 className="contact-info__title">About</h4>
									<p className="contact-info__text" dangerouslySetInnerHTML={{__html:siteDetails?.aboutus??""}}></p>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4">

								<div className="contact-info__single">
									<h4 className="contact-info__title">Address</h4>
									<p className="contact-info__text">
										{/* Vijay Krishna apartment Chalasani venkata Krishnayya Street, Surya Rao peta Vijayawada, AndhraPradesh 520010 India */}
										{siteDetails?.address??""}
									</p>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4">

								<div className="contact-info__single contact-info__single-3">
									<h4 className="contact-info__title">Contact</h4>
									<p className="contact-info__email-phone">
										<a href="mailto:ardocare.ap@gmail.com"
											className="contact-info__email">{siteDetails?.email??""}</a>
										<a href="tel:+917989383246" className="contact-info__phone">{siteDetails?.contactNumber??""}</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		)
	}
}