import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterOne from '../common/footer/FooterOne';

class Privacy extends React.Component {
    render() {
        return (
            <>
                <HeaderOne />
                <Breadcrumb
                    heading="Privacy Policy"
                    currentPage="Privacy Policy"
                />
                <div className="terms-container container">
                    <p>Effective Date: September 24, 2023</p>
                    <h2 className="h2">1. Introduction</h2>
                    <p>
                        ARDO is committed to protecting the privacy and personal information of individuals who interact with us, including donors, volunteers, website visitors, and other stakeholders. This Privacy Policy outlines our practices regarding the collection, use, disclosure, and security of personal information.</p>
                    <h2 className="h2">2. Information We Collect</h2>
                    <p>
                        We may collect and process various types of personal information, including but not limited to:
                        Contact Information: Names, addresses, email addresses, and phone numbers.
                        Donor Information: Donation history and payment details.
                        Volunteer Information: Information related to volunteer activities and preferences.
                        Medical Information: Information shared by individuals for research or support purposes.
                        Website Usage Information: Information collected through cookies and similar technologies, including IP addresses, browser types, and pages viewed.                    </p>

                    <h2 className="h2">3. How We Use Your Information</h2>
                    <p>
                        We may use your personal information for the following purposes:
                        To communicate with you, respond to inquiries, and provide information.
                        To process donations and acknowledge contributions.
                        To manage volunteer activities and coordinate events.
                        To conduct research related to muscular dystrophy.
                        To improve our website and services.
                        To comply with legal and regulatory requirements.
                    </p>
                    <h2 className="h2">4. Sharing of Personal Information</h2>
                    <p>
                        We may share personal information with trusted third parties, including:
                        Service Providers: We may share information with service providers who assist us in fulfilling our mission, such as payment processors and email communication platforms.
                        Research Partners: We may share de-identified data with research organizations for scientific and medical research purposes.
                        Legal Requirements: We may disclose personal information when required by law, court order, or other legal processes.
                    </p>
                    <h2 className="h2">5. Your Choices</h2>
                    <p>
                        You have the right to:
                        Access, correct, or delete your personal information.
                        Opt out of receiving communications from us.
                        Withdraw consent for processing where applicable.
                    </p>
                    <h2 className="h2">6. Security</h2>
                    <p>

                        We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.

                    </p>
                    <h2 className="h2">7. Changes to this Privacy Policy</h2>
                    <p>
                        We may update this Privacy Policy periodically. Any changes will be posted on our website with the effective date.                    </p>
                    <h2 className="h2">8. Contact Us</h2>
                    <p>
                        If you have questions or concerns regarding this Privacy Policy or wish to exercise your rights, please contact us at:
                        <br/>
                        Phone Number: +91 70933 35285
                        <br/>
                        Email: ardocare.ap@gmail.com
                        <br/>
                        Website: ardo.org.in
                        <br/>
                        Office Address:
                        Vijayawada, Andhra Pradesh - 520002
                        <br/>
                        Home Address:
                        Plot No. 2/125B, Nuzvid City,
                        Krishna District, Vijayawada, Andhra Pradesh - 521211
                    </p>

                </div>
                <FooterOne />
            </>
        );
    }
}

export default Privacy;
