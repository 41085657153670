import React from 'react';
import { Link } from 'react-router-dom';
import { UilFacebook, UilTimes, UilInstagram,UilLinkedinAlt, UilYoutube } from '@iconscout/react-unicons';
import RestServices from '../../services/rest.service';
import EndpointConstants from '../../constants/Endpoint.constants';
 

export default class FooterOne extends React.Component {

	restService=new RestServices();
	constructor(props) {
		super(props);
		this.state = {
			gallery: [],
			siteSettings:{}
			// Initialize state
		};
		
	}


	componentDidMount() {

		const $ = window.$;

		if ($(".footer-widget-one__twitter-feed-content").length) {
			$(".footer-widget-one__twitter-feed-content").owlCarousel({
				loop: true,
				autoplay: true,
				margin: 30,
				nav: false,
				dots: true,
				smartSpeed: 500,
				autoplayTimeout: 10000,
				navText: ["<span class=\"fa fa-angle-left\"></span>", "<span class=\"fa fa-angle-right\"></span>"],
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 1
					},
					991: {
						items: 1
					},
					1200: {
						items: 1
					}
				}
			});
		}
		this.getGalleryDetails();
		this.getSiteSettings();
	}

	getGalleryDetails(){
		this.restService.doGet(EndpointConstants.GET_GALLERY).then(({data})=>{
			console.log(data);
			console.log("gallery", data);
			this.setState({gallery:data});
		}).then(({err})=>{	
			console.log(err);
			
		})
	}


	getSiteSettings(){
		this.restService.doGet(EndpointConstants.GET_SITE_SETTINGS).then(({data})=>{
			console.log("check data aas", data);
			this.setState({
				siteSettings:data
			})
		}).catch((err)=>{
			console.log(err);
		})
	}
	handleClick = () => {
		// Navigate to a specific page when the element is clicked
		this.props.history.push('/terms'); // Replace '/new-page' with the path you want to navigate to
	}; 

	// getFooterDetails=()=>{
	// 	this.restService.getFooterDetails().then((response) => {
	// 		console.log(response.data);
	// 		this.setState({footerDetails:response.data});
	// 	}).catch((error) => {
	// 			console.log(error);
	// 	});

	// }

	// getGallery=()=>{
	// 	this.restService.getGallery().then((response) => {
	// 		console.log(response.data);
	// 		this.setState({gallery:response.data});
	// 	}).catch((error) => {
	// 		console.log(error);
	// 	});
	// }


	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<>
				<footer className="site-footer-one">

					<div className="site-footer-one__top">
					<div class="custom-shape-divider-top-1702463991">
						<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
							<path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
						</svg>
					</div>
						<div className="container footer-im">
							<div className="row">

								<div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
									<div className="footer-widget__column footer-widget-one__about">
										<div className="footer-widget-one__about-logo">
											<Link to={process.env.PUBLIC_URL + `/`}><img src={publicUrl + "assets/images/resources/mainlogoo.png"} alt="" /></Link>
										</div>
										<p className="footer-widget-one__about-text">
										{/* Vijay Krishna apartment Chalasani venkata Krishnayya Street, Surya Rao peta Vijayawada, AndhraPradesh  520010 India 							 */}
										{/* {this.state.footerDetails.address} */}
										</p>
										<ul className="list-unstyled footer-widget-one__list">
											<li>
												<div className="icon">
													<span className="icon-call"></span>
												</div>
												<div className="text">
													<p><a href={"tel:"+this.state.siteSettings?.contactNumber??""} target='_blank' rel="noopener noreferrer">{this.state.siteSettings?.contactNumber??""}</a></p>
												</div>
											</li>
											<li>
												<div className="icon">
													<span className="icon-envelope"></span>
												</div>
												<div className="text">
													<p><a href="mailto:ardocare.ap@gmail.com" target='_blank' rel="noopener noreferrer">{this.state.siteSettings?.email??""}</a></p>
												</div>
											</li>

										</ul>
									</div>
								</div>

								<div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
									<div className="footer-widget__column footer-widget-one__gallery clearfix">
										<h3 className="footer-widget-one__title">Gallery</h3>
										<ul className="footer-widget-one__gallery-list list-unstyled clearfix">
											{this.state.gallery.map((item)=>{
												console.log(item.url)
												return (
													<li>
														<div className="footer-widget-one__gallery-img">
															<img src={item.imageUrl} alt="" />
															<Link to={process.env.PUBLIC_URL + `/gallery`}><span className="fa fa-link"></span></Link>
														</div>
													</li>
												)
											})}
											{/* <li>
												<div className="footer-widget-one__gallery-img">
													<img src={publicUrl + "assets/images/resources/footer-widget-one-gallery-2.jpg"} alt="" />
													<Link to={process.env.PUBLIC_URL + `/gallery`}><span className="fa fa-link"></span></Link>
												</div>
											</li>
											<li>
												<div className="footer-widget-one__gallery-img">
													<img src={publicUrl + "assets/images/resources/footer-widget-one-gallery-3.jpg"} alt="" />
													<Link to={process.env.PUBLIC_URL + `/gallery`}><span className="fa fa-link"></span></Link>
												</div>
											</li>
											<li>
												<div className="footer-widget-one__gallery-img">
													<img src={publicUrl + "assets/images/resources/footer-widget-one-gallery-4.jpg"} alt="" />
													<Link to={process.env.PUBLIC_URL + `/gallery`}><span className="fa fa-link"></span></Link>
												</div>
											</li>
											<li>
												<div className="footer-widget-one__gallery-img">
													<img src={publicUrl + "assets/images/resources/footer-widget-one-gallery-5.jpg"} alt="" />
													<Link to={process.env.PUBLIC_URL + `/gallery`}><span className="fa fa-link"></span></Link>
												</div>
											</li>
											<li>
												<div className="footer-widget-one__gallery-img">
													<img src={publicUrl + "assets/images/resources/footer-widget-one-gallery-6.jpg"} alt="" />
													<Link to={process.env.PUBLIC_URL + `/gallery`}><span className="fa fa-link"></span></Link>
												</div>
											</li>
											<li>
												<div className="footer-widget-one__gallery-img">
													<img src={publicUrl + "assets/images/resources/footer-widget-one-gallery-7.jpg"} alt="" />
													<Link to={process.env.PUBLIC_URL + `/gallery`}><span className="fa fa-link"></span></Link>
												</div>
											</li>
											<li>
												<div className="footer-widget-one__gallery-img">
													<img src={publicUrl + "assets/images/resources/footer-widget-one-gallery-8.jpg"} alt="" />
													<Link to={process.env.PUBLIC_URL + `/gallery`}><span className="fa fa-link"></span></Link>
												</div>
											</li>
											<li>
												<div className="footer-widget-one__gallery-img">
													<img src={publicUrl + "assets/images/resources/footer-widget-one-gallery-9.jpg"} alt="" />
													<Link to={process.env.PUBLIC_URL + `/gallery`}><span className="fa fa-link"></span></Link>
												</div>
											</li> */}


										</ul>

									</div>
								</div>
								<div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
									<div className="footer-widget__column footer-widget-one__latest-works clearfix">
										<h3 className="footer-widget-one__title">Quick Links</h3>
										<li className='links'>
											<Link to={`${process.env.PUBLIC_URL}/`}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
												<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
											</svg>Home</Link>
										</li>
										<li className='links'>
											<Link to={`${process.env.PUBLIC_URL}/about`}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
												<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
											</svg> About Us</Link>
										</li>
										<li className='links'>
											<Link to={`${process.env.PUBLIC_URL}/projects`}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
												<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
											</svg> Projects</Link>
										</li>
										<li className='links'>
											<Link to={`${process.env.PUBLIC_URL}/contact`}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
												<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
											</svg>Contact Us</Link>
										</li>
										<li className='links'>
											<Link to={`${process.env.PUBLIC_URL}/terms`}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
												<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
											</svg> Terms and Conditions</Link>
										</li>
										<li className='links'>
											<Link to={`${process.env.PUBLIC_URL}/privacy`}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
												<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
											</svg> Privacy Policy</Link>
										</li>
										<li className='links'>
											<Link to={`${process.env.PUBLIC_URL}/Cancellation`}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
												<path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
											</svg> Cancellation & Refund</Link>
										</li>
										 
									</div>
								</div>
								<div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
									<div className="footer-widget__column footer-widget-one__twitter-feed clearfix">
										<h3 className="footer-widget-one__title">Latest Activities</h3>
										<div className="owl-carousel owl-theme thm-owl__carousel footer-widget-one__twitter-feed-content">
											<div className="footer-widget-one__twitter-feed-single">
												<p className="footer-widget-one__twitter-feed-text">
													GENETIC TEST AND SOCIAL AWARENESS CAMP, JANUARY 2019 
													<a href="https://x.com/ardo_care" rel="noopener noreferrer" target='_blank'>
														http://t.twitter.com
													</a>
												</p>
												<div className="footer-widget-one__twitter-feed-bottom">
													<div>
														<p>
															Muscular Dystrophy cannot be identified until genetic tests are done. Considered as a rare disease it is important to let people know about it..
														</p>
													</div>
												</div>
											</div>
											<div className="footer-widget-one__twitter-feed-single">
												<p className="footer-widget-one__twitter-feed-text">
													MOTHER’S DAY EVENT, MAY 2019 
													<a href="http://t.twitter.com/" rel="noopener noreferrer" target='_blank'>http://t.twitter.com</a></p>
												<div className="footer-widget-one__twitter-feed-bottom">
													<div>
														<p>Mothers are treated as God. To recognize their efforts and as a mark of respect for the tireless efforts they put in to carefully up bring chil..</p>
													</div>
												</div>
											</div>
											<div className="footer-widget-one__twitter-feed-single">
												<p className="footer-widget-one__twitter-feed-text">COVID-19 FOOD DISTRIBUTION DRIVE   <a href="http://t.twitter.com" rel='noreferrer' target='_blank'>http://t.twitter.com</a></p>
												<div className="footer-widget-one__twitter-feed-bottom">
													<div>
														<p>While the fear of Covid-19 has locked the entire globe, ARDO has chosen to fearlessly rise and carry out food donation drive to feed the hun..</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='col-xl-12 col-lg-12   fadeInUp justify-content-center' data-wow-delay="400ms">
								<div  className='social_icons footer-widget__column footer-widget-one__twitter-feed clearfix'>
								<a  href="https://www.facebook.com/ardo.org"  rel="noopener noreferrer" target="_blank">
								<UilFacebook size="20" />
								</a>
								<a  href="https://x.com/ardo_care"  rel="noopener noreferrer" target="_blank">
								<UilTimes  size="20" />
								</a>
								<a  href="https://www.instagram.com/ardo_musculardystrophy/"  rel="noopener noreferrer" target="_blank">
								<UilInstagram size="20" />
								</a>
								<a href="https://www.linkedin.com/company/ardo-musculardystrophy/"  rel="noopener noreferrer" target="_blank">
									<UilLinkedinAlt size="20" />
								</a>
								<a href="https://www.youtube.com/c/ARDOManasaVaacha"  rel="noopener noreferrer" target="_blank">
									<UilYoutube size="20" />
								</a>
								</div>
								
								</div>
							</div>
						</div>
					</div>
					<div className="site-footer__bottom">
						<div className="container">
							<div className="site-footer__bottom-inner">
								<p className="site-footer__bottom-text">Copyright © ARDO 2023. All Rights Reserved</p>
							</div>
						</div>
					</div>
				</footer>
				<div className="whatsapp_boxx">
					<a href="https://api.whatsapp.com/send?phone=%2B917989383246&lang=en"  rel="noopener noreferrer" target='_blank'>
						<img src={publicUrl + "assets/images/icon/whatappicon.png"} alt="whats app icons" />
					</a>
				</div>
				<div className="mail-sa">
					<a href="mailto:ardocare.ap@gmail.com" target='_blank' rel="noopener noreferrer">
						<img src={publicUrl + "assets/images/icon/mail.webp"} alt="mail icons" />
					</a>
				</div>
			</>
		)
	}
}