import React from 'react';
import RestService from '../../services/rest.service'
import EndpointConstants from '../../constants/Endpoint.constants';
export default class AboutOne extends React.Component {

	restService=new RestService();


	constructor(props) {
		super(props);
		this.state = {
			siteSettings: {}, // Initialize state
		};
		
	}
	componentDidMount() {
		const $ = window.$;		
		if ($(".video-popup").length) {
			$(".video-popup").magnificPopup({
				type: "iframe",
				mainClass: "mfp-fade",
				removalDelay: 160,
				preloader: true,
				fixedContentPos: false,
			});
		}
		this.getSiteData();
	}

	getSiteData(){
		this.restService.doGet(EndpointConstants.GET_SITE_SETTINGS).then(({data})=>{
			this.setState({siteSettings:data});
		}).catch((err)=>{
			console.log(err);
		})
	}
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<>
				<section className="about-one about-three">
					<div className="container">
						<div className="row">
							<div className="col-xl-6">
								<div className="about-one__left wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
									<div className="about-one__img-box">
										<div className="about-one__img">
											<img src={publicUrl + "assets/images/resources/about-one-img-1.jpg"} alt="" />
										</div>
										<div className="about-one__small-img wow zoomIn animated animated" data-wow-delay="500ms"
											data-wow-duration="2500ms">
											<img src={publicUrl + "assets/images/resources/about-one-small-img.jpg"} alt="" />
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-6">
								<div className="about-one__right">
									<div className="about-one__right-content">
										<div className="section-title text-left">
											<span className="section-title__tagline">About Us</span>
											<h2 className="section-title__title">We Can Save More Lifes With Your Helping Hand.</h2>
										</div>
										<p className="about-one__text">Amaravati Rare Diseases Organisation is a section 25 Non-governmental Organisations it is established in 2019 to support individuals in Andhra Pradesh who are suffering with a rare condition called ‘Muscular Dystrophy’.</p>
										<ul className="list-unstyled about-one__points">
											<li>
												{/* <div className="icon">
													<span className="icon-comment"></span>
												</div> */}
												<div className="text">
													{/* <h4>It service for business network.</h4> */}
													<p>ARDO aims at providing care and services to the patients by organising awareness camps, diagnosis tests, physiotherapy treatment and distributing wheelchairs.</p>
												</div>
											</li>
											<li>
												{/* <div className="icon">
													<span className="icon-comment"></span>
												</div> */}
												<div className="text">
													{/* <h4>Business success rate solving.</h4> */}
													<p>To the greatest extent feasible, ARDO is very pro-active in its efforts to advance society. By extending its mission outside its core initiatives, when it firmly holds that doing good deeds for everyone would make the world a better place to live.</p>
												</div>
											</li>
										</ul>
										<div className="about-one__bottom-video-box">
											<div className="about-one__btn-box">
											   
											</div>
											<div className="about-one__video-link d-flex">
												<a href={this.state.siteSettings.youtubeLink} className="video-popup">
													<div className="about-one__video-icon">
														<span className="fa fa-play"></span>
														<i className="ripple"></i>
													</div>
												</a>&nbsp;&nbsp;
												<div style={{position:'relative', marginTop:4, marginLeft:29 }} className="h2">Play Video</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		)
	}
}