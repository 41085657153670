import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useRazorpay from 'react-razorpay';
import RestServices from '../../services/rest.service';
import EndpointConstants from '../../constants/Endpoint.constants';

const schema = yup.object().shape({
  amount: yup.number().min(10, 'Donation amount must be at least ₹10').required('Required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Invalid email').required('Required'),
  mobileNumber: yup.string().matches(/^\d{10}$/, 'Invalid phone number').required('Required'),
  address: yup.string().required('Address is required'),
  country: yup.string().required('Country is required'),
  comment: yup.string().required('Comment is required'),
});

const DonateForm = () => {
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const retrivedtoken = localStorage.getItem('token');
  const restservice=new RestServices();
  const { 
    register, 
    handleSubmit, 
    formState: { errors, isSubmitting }, 
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: 'nomail@gmail.com'
    }
  });

  const DonateNow = async (payload) => {
	try{
    payload.ispaymentgatewayrequired=true;
    delete payload.phone;
		restservice.doPost(EndpointConstants.DONATE_NOW,payload).then(({data})=>{
			console.log(data);
      debugger
      let response=data;
      console.log(response.paymentLink.instrumentResponse.redirectInfo.url)
      window.location.href=response.paymentLink.instrumentResponse.redirectInfo.url
		}).catch((err)=>{
			console.log(err);

		})
	}catch(err){
		console.log(err);
		toast.error("Something went wrong, please contatct support team")
	}
  };


  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-8 col-lg-7 donate_noww">
          <div className="donate-now__left">
            <form className="donate-now" onSubmit={handleSubmit(DonateNow)}>
              <div className="donate-now__enter-donation ">
                <h3 className="donate-now__title">Enter Your Donation</h3>
                <div className="donate-now__enter-donation-input">
                  <input 
				  className='w-100'
                    {...register('amount')} 
                    placeholder="Enter amount" 
                  />
                  <div className="selectpicker">₹</div>
                </div>
                {errors.amount && <p className="error-message">{errors.amount.message}</p>}
              </div>

              <div className="donate-now__personal-info-box">
                <h3 className="donate-now__title">Personal Info</h3>
                <div className="donate-now__personal-info-form">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="donate-now__personal-info-input">
                        <input 
							type='text'
                          {...register('firstName')} 
                          placeholder="First Name" 
                        />
                        {errors.firstName && <p className="error-message">{errors.firstName.message}</p>}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="donate-now__personal-info-input">
                        <input 
						type='text'
                          {...register('lastName')} 
                          placeholder="Last Name" 
                        />
                        {errors.lastName && <p className="error-message">{errors.lastName.message}</p>}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="donate-now__personal-info-input">
                        <input 
                          type="email"
                          {...register('email')} 
                          placeholder="Email Address" 
                        />
                        {errors.email && <p className="error-message">{errors.email.message}</p>}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="donate-now__personal-info-input">
                        <input 
						type='number'
                          {...register('mobileNumber')} 
                          placeholder="Phone Number" 
                        />
                        {errors.mobileNumber && <p className="error-message">{errors.mobileNumber.message}</p>}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="donate-now__personal-info-input">
                        <input 
						type="text"
                          {...register('address')} 
                          placeholder="Address" 
                        />
                        {errors.address && <p className="error-message">{errors.address.message}</p>}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="donate-now__personal-info-input">
                        <Form.Select 
                          {...register('country')}
                          className="selectpicker" 
                          aria-label="Country select"
                        >
                          <option value="">Select Country</option>
                          <option value="India">India</option>
                          <option value="USA">United States</option>
                          <option value="UK">United Kingdom</option>
                        </Form.Select>
                        {errors.country && <p className="error-message">{errors.country.message}</p>}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-12">
                      <div className="donate-now__personal-info-input donate-now__personal-info-message-box">
                        <textarea 
                          {...register('comment')} 
                          placeholder="Write a Comment" 
                        />
                        {errors.comment && <p className="error-message">{errors.comment.message}</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="donate-now__payment-info-btn-box">
                <button 
                  type="submit" 
                  className="thm-btn donate-now__payment-info-btn"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Donate now'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonateForm;