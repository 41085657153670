import axiosInstance from "../configs/axios.config";



class RestServices{



    doPost(endpoint, payload){
        return axiosInstance.post(endpoint, payload,{timeout:200000});
    }


    doGet(endpoint){
        return axiosInstance.get(endpoint);
    }


    doPut(endpoint, payload){
        return axiosInstance.put(endpoint, payload);
    }
}



export default RestServices;