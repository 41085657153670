



const EndpointConstants={

    GET_GALLERY:`gallery/getGallery`,
    GET_EVENTS:`events/getEvents`,
    GET_SITE_SETTINGS:`site-settings/getSiteSettings`,
    GET_EVENTS:`events/getEvents`,
    GET_EVENT_BY_ID:`events/getEventById/{id}`,
    GET_MEMBERS:`members/getMembers`,
    SEND_MESSAGE:`messages/sendMessage`,
    REGISTER_USER:`user/registerUser`,
    ADD_PATIENTS:`patients/add-patients`,
    DONATE_NOW:`donars/add-donars`,
    ADD_VOLUNTEER:`volunteers/add-volunteer`
}



export default EndpointConstants;