import React, { useEffect, useState } from 'react';
import RestServices from '../../services/rest.service';
import EndpointConstants from '../../constants/Endpoint.constants';
import { useNavigate } from 'react-router-dom';
const ProjectOne = () => {

	const [projects, setProjects] = useState([]);
	const restService = new RestServices();
	const navigate=useNavigate();

	useEffect(() => {
		restService.doGet(EndpointConstants.GET_EVENTS).then(({ data }) => {
			setProjects(data.filter((item) => item.eventType == "projects"));
			//this.doChanges();
		})

	}, [])

	return (
		<>
			<section className="project-one">
				<div className="container">
					<div className="section-title text-center">
						<span className="section-title__tagline">Our Causes Events</span>
						<h2 className="section-title__title">We Popular To Provide <br /> Best Projects.</h2>
					</div>
					<div className="row">
						{projects.map((item) => {
							return (
								<div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" onClick={()=>navigate("details/"+item.id)} data-wow-delay="100ms">

									<div className="project-one__single">
										<div className="project-one__img">
											<img src={item.eventImage} alt="" />
											<div className="project-one__content">
												<p className="project-one__sub-title">{item.title}</p>
												<h3 className="project-one__title" dangerouslySetInnerHTML={{ __html: item.summary }}></h3>
											</div>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</section>
		</>
	)
}



export default ProjectOne;