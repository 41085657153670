import { useForm } from 'react-hook-form';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import RestService from "../../services/rest.service";
import EndpointConstants from "../../constants/Endpoint.constants";

const VolunteerForm = () => {
  const navigate = useNavigate();
  const restservice=new RestService();
  const {
    register,
    handleSubmit,
	reset,
    formState: { errors, isSubmitting },
  } = useForm();
  
  const onSubmit = async (data) => {
    // TODO: Add your submission logic here
	debugger;
	restservice.doPost(EndpointConstants.ADD_VOLUNTEER, data).then(({data})=>{
		reset();
		toast.success("Your data submitted successfully")
	}).catch((err)=>{
		console.log(err);
	})
	
    // Example API call:
    // try {
    //   const response = await fetch('your-api-endpoint', {
    //     method: 'POST',
    //     body: JSON.stringify(data)
    //   });
    //   navigate('/success');
    // } catch (error) {
    //   toast.error('Submission failed');
    // }
  };

  return (
    <form className="donate-now" onSubmit={handleSubmit(onSubmit)}>
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-7 donate_noww">
            <div className="donate-now__left">
              <div className="donate-now__personal-info-box">
                <h3 className="donate-now__title">Volunteer Info</h3>
                <div className="donate-now__personal-info-form">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="donate-now__personal-info-input">
                        <input
                          type="text"
                          placeholder="First Name"
                          {...register('firstName', { required: 'First Name is required' })}
                        />
                        {errors.firstName && (
                          <p className="error-message">{errors.firstName.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="donate-now__personal-info-input">
                        <input
                          type="text"
                          placeholder="Last Name"
                          {...register('lastName', { required: 'Last Name is required' })}
                        />
                        {errors.lastName && (
                          <p className="error-message">{errors.lastName.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="donate-now__personal-info-input">
                        <input
                          type="email"
                          placeholder="Email Address"
                          {...register('email', {
                            required: 'Email is required',
                            pattern: {
                              value: /^\S+@\S+$/i,
                              message: 'Invalid email address',
                            },
                          })}
                        />
                        {errors.email && (
                          <p className="error-message">{errors.email.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="donate-now__personal-info-input">
                        <input
                          type="text"
                          placeholder="Phone Number"
                          {...register('phoneNumber', {
                            required: 'Phone number is required',
                            pattern: {
                              value: /^\d{10}$/,
                              message: 'Invalid phone number',
                            },
                          })}
                        />
                        {errors.phone && (
                          <p className="error-message">{errors.phoneNumber.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="donate-now__personal-info-input donate-now__personal-info-message-box">
                        <textarea
                          placeholder="Write a Comment"
                          {...register('comment', { required: 'Comment is required' })}
                        />
                        {errors.comment && (
                          <p className="error-message">{errors.comment.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="donate-now__payment-info-btn-box">
                <button
                  type="submit"
                  className="thm-btn donate-now__payment-info-btn"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Spinner
                      animation="border"
                      role="status"
                      variant="success"
                      size="sm"
                      className="spinner"
                    />
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default VolunteerForm;