import React from 'react';
import { useForm } from 'react-hook-form';
import { BeatLoader } from 'react-spinners';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import RestServices from "../../services/rest.service";
import { ToastContainer, toast } from 'react-toastify';
import EndpointConstants from "../../constants/Endpoint.constants";
// Validation schema
const schema = yup.object().shape({
  name: yup.string().required('First Name is required'),
  phone_number: yup.string().required('Mobile Number is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  subject: yup.string().required('Subject is required'),
  comment: yup.string().required('Message is required'),
});

const ContactForm = () => {
  const { 
    register, 
    handleSubmit, 
    reset,
    formState: { errors, isSubmitting } 
  } = useForm({
    resolver: yupResolver(schema)
  });


  const restservice=new RestServices();

  const onSubmit = async (data) => {
    try {
		console.log("sample check",data);
		restservice.doPost(EndpointConstants.SEND_MESSAGE, data).then(({data})=>{
			toast.success('Your message submitted successfully!');
			reset();
		}).catch((err)=>{
			console.log(err);
		})
      // Replace with your API call
	}
	catch(e){
		console.log(e);
	}
  };

  return (
    <section className="contact-page">
      <div className="contact-page-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/contact-page-bg.jpg)' }}></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="contact-page__left">
              <div className="section-title text-left">
                <span className="section-title__tagline">Our Contact now</span>
                <h2 className="section-title__title">We out The Form Prepared at <br /> Your Contact?</h2>
              </div>
              <div className="contact-page__form">
                <form onSubmit={handleSubmit(onSubmit)} className="comment-one__form">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="comment-form__input-box">
                        <input
                          type="text"
                          placeholder="Name"
                          {...register('name')}
                        />
                        {errors.name && <span className="error-message">{errors.name.message}</span>}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="comment-form__input-box">
                        <input
                          type="number"
                          placeholder="Mobile Number"
                          {...register('phone_number')}
                        />
                        {errors.phone_number && <span className="error-message">{errors.phone_number.message}</span>}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="comment-form__input-box">
                        <input
                          type="email"
                          placeholder="Email address"
                          {...register('email')}
                        />
                        {errors.email && <span className="error-message">{errors.email.message}</span>}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="comment-form__input-box">
                        <input
                          type="text"
                          placeholder="Subject"
                          {...register('subject')}
                        />
                        {errors.subject && <span className="error-message">{errors.subject.message}</span>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="comment-form__input-box text-message-box">
                        <textarea 
                          placeholder="Write a comment" 
                          {...register('comment')}
                        ></textarea>
                        {errors.comment && <span className="error-message">{errors.comment.message}</span>}
                      </div>
                      <div className="comment-form__btn-box">
                        <button 
                          type="submit" 
                          className="thm-btn comment-form__btn" 
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <BeatLoader color="#fff" size={8} />
                          ) : (
                            'Send a message'
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="contact-page__right">
              <div className="contact-page__img">
                <img src="assets/images/resources/contact-page-img-1.jpg" alt="" />
                <div className="contact-page__img-shape">
                  <img src="assets/images/shapes/contact-page-img-shape.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;