import React from 'react';

export default class Thankyou extends React.Component {
  
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<>

				<section className="about-one Vision-one about-three">
					<div className="container">
						<div className="row"> 
                        <div className='thankyou-img' >
                        <img src={publicUrl + "assets/images/thankyou.png  "} alt="" />
                        <p>From <span> "Amaravati Rare Diseases Organisation"</span></p>
                        </div>
						</div>
					</div>
				</section>

			</>
		)
	}
}