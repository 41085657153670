import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterOne from '../common/footer/FooterOne';

class TermsAndConditions extends React.Component {
    render() {
        return (
            <>
                <HeaderOne />
                <Breadcrumb
                    heading="Terms And Conditions"
                    currentPage="Terms And Conditions"
                />
                <div className="terms-container container">
                    <h2 className="h2">1. Acceptance of Terms</h2>
                    <p>
                        By accessing or using Amaravati Rare Diseases Organization(ARDO)'s website, you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use the website.
                    </p>

                    <h2 className="h2">2. Changes to Terms</h2>
                    <p>
                        Amaravati Rare Diseases Organization(ARDO) reserves the right to modify or revise these Terms and Conditions at any time without prior notice. Your continued use of the website following any changes constitutes your acceptance of those changes.
                    </p>

                    <h2 className="h2">3. Use of Website</h2>
                    <p>
                        You agree to use the website for lawful purposes only.
                        You may not engage in any activity that disrupts or interferes with the website's functionality.
                        You are responsible for maintaining the confidentiality of your account information and password.
                    </p>
                    <h2 className="h2">4. Donations</h2>
                    <p>
                        All donations made to ARDO are voluntary and non-refundable unless otherwise specified.
                        Amaravati Rare Diseases Organization(ARDO) will make reasonable efforts to ensure the security and privacy of your payment information but is not liable for any breaches or unauthorized access.
                    </p>
                    <h2 className="h2">5. Intellectual Property</h2>
                    <p>
                        All content on this website, including text, graphics, logos, images, and software, is the property of Amaravati Rare Diseases Organization(ARDO) and is protected by copyright laws.
                        You may not reproduce, distribute, or use any content from this website without prior written permission from Amaravati Rare Diseases Organization(ARDO).
                    </p>
                    <h2 className="h2">6. Privacy</h2>
                    <p>
                        ARDO collects, uses, and protects personal information as described in the Privacy Policy.
                        By using the website, you consent to the collection and use of your information as outlined in the Privacy Policy.
                    </p>
                    <h2 className="h2">7. External Links</h2>
                    <p>
                        The website may contain links to third-party websites for informational purposes. Amaravati Rare Diseases Organization(ARDO) does not endorse or have control over the content or policies of external websites and is not responsible for their practices.
                    </p>
                    <h2 className="h2">8. Limitation of Liability</h2>
                    <p>
                        ARDO is not liable for any damages, losses, or claims arising from your use of the website or reliance on its content.
                    </p>
                    <h2 className="h2">9. Governing Law</h2>
                    <p>
                        These Terms and Conditions are governed by and constructed in accordance with the laws of Bharat (India), and you agree to submit to the exclusive jurisdiction of its courts.
                    </p>
                    <h2 className="h2">10. Contact Information</h2>
                    <p>
                    <p><strong>Phone Number:</strong> +91 70933 35285</p>
                <p><strong>Email:</strong> <a href="mailto:ardocare.ap@gmail.com">ardocare.ap@gmail.com</a></p>
                <p><strong>Website:</strong> <a href="https://ardo.org.in" target="_blank" rel="noopener noreferrer">ardo.org.in</a></p>
                <p><strong>Office Address:</strong><br />
                Vijayawada, Andhra Pradesh - 520002</p>
                <p><strong>Home Address:</strong><br />
                Plot No. 2/125B, Nuzvid City,
                Krishna District, Vijayawada, Andhra Pradesh - 521211</p>
                    </p>
                </div>
                <FooterOne />
            </>
        );
    }
}

export default TermsAndConditions;
