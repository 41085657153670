import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name : "",
  email : "",
  password : "",
  confirmPassword : "",
  phoneNumber : "",
  dateOfBirth : "",
  gender : "",
  address1 : "",
  address2 : "",
  city : "",
  country : "",
  region : "",
  pincode : "",
  parentname : "",
  parent_occupation : "",
  education : "",
  services : [],
  genetic_test : "",
  consulted_doctor_name : "",
  profilepicture : "",
  aadhardocuments : "",
  rationdocuments : "",
  sadaremdocuments : "",
  uploaddocuments : "",
  disease : "",
  age : "",
  amount: '',
  document_name : "",
  present_situation : [],
  job_status : "",
  school_status : "",
  state: "",
  health_status : "",
  errors: {
    name : "",
    email : "",
    password : "",
    confirmPassword : "",
    phoneNumber : "",
    dateOfBirth : "",
    gender : "",
    address1 : "",
    address2 : "",
    city : "",
    country : "",
    region : "",
    pincode : "",
    parentname : "",
    parent_occupation : "",
    education : "",
    services : "",
    genetic_test : "",
    consulted_doctor_name : "",
    profilepicture : "",
    aadhardocuments : "",
    rationdocuments : "",
    sadaremdocuments : "",
    uploaddocuments : "",
    disease : "",
    age : "",
    amount: '',
  },
}

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    handleChange: (state=initialState, action) => {
      const { name, value } = action.payload;
      state[name] = value;
      return state;
    },
    handleInputCheck: (state, action) =>{

      const { name, value } = action.payload;
      // Check if the value is already in the array
      if (state[name].includes(value)) {
        // If it's in the array, remove it
        state[name] = state[name].filter(item => item !== value);
      } else {
        // If it's not in the array, add it
        state[name] = [...state[name], value];
      }
      
      return state;
    },
    validateForm: (state, action) => {
      const errors = {};
      if (!state.name) {
        errors.name = 'Name is required';
      }
     
      const phonePattern = /^[789]\d{9}$/;
      if (!state.phoneNumber || !phonePattern.test(state.phoneNumber)) {
        errors.phoneNumber = 'Invalid phone number';
      }
    
      if (!state.age) {
        errors.age = 'Age is required';
      }
      if (!state.consulted_doctor_name) {
        errors.consulted_doctor_name = 'Consulted is required';
      } 
      // if (!state.profilepicture) {
      //   errors.profilePicture = 'profilePicture is required';
      // }
      if (!state.dateOfBirth) {
        errors.dateOfBirth = 'Date of birth is required';
      }
      
      if (!state.genetic_test) {
        errors.genetic_test = 'Genetic is required';
      }
      if (!state.gender) {
        errors.gender = 'Gender is required';
      }
      if (state.amount < 0) {
        errors.amount = "Donation amount must be greater than or equal to 100";
      }
      if (!state.address1) {
        errors.address1 = 'Address is required';
      }
      if (!state.parentname) {
        errors.parentname = 'Name is required';
      }
      if (!state.parent_occupation) {
        errors.parent_occupation  = 'Occupation is required';
      }
      if (!state.disease) {
        errors.disease  = ' Disease  is required';
      } 
      if (!state.profilepicture) {
        errors.profilepicture  = ' Profile Pic  is required';
      } 
       
       if (!state.services) {
        errors.services   = ' Services   is required';
      }
      if (!state.country) {
        errors.country = ' Country is required';
      }
      if (!state.education) {
        errors.education = 'Education is required';
      }

      if (!state.city) {
        errors.city = 'City is required';
      }

      if (!state.region) {
        errors.region = 'District is required';
      }

      if (!state.pincode) {
        errors.pincode = 'Pin code is required';
      }
      state.errors = errors;
    },
    resetForm: (state) => {
      return initialState;
    },
  },

})

export const { handleChange, handleInputCheck, validateForm, resetForm } = registerSlice.actions

export default registerSlice.reducer