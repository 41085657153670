import React from 'react';

export default class ContactMap extends React.Component {
    render() {
        return (
            <>
                <section className="contact-page-google-map">
                    <iframe
                        title="Address"
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3825.3143135335813!2d80.63514727514539!3d16.51022218423499!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTbCsDMwJzM2LjgiTiA4MMKwMzgnMTUuOCJF!5e0!3m2!1sen!2sin!4v1740580771377!5m2!1sen!2sin"
                        className="contact-page-google-map__one" allowfullscreen></iframe>

                </section>
            </>
        )
    }
}