import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterOne from '../common/footer/FooterOne';

class Privacy extends React.Component {
    render() {
        return (
            <>
                <HeaderOne />
                <Breadcrumb
                    heading="Cancellation & Refund"
                    currentPage="Cancellation & Refund  "
                />
                <div className="terms-container container">
                    <p>Last updated on Dec 5th 2023</p>
                    <h2 className="h2"> </h2>
                    <p>
                    Thank you for choosing Amaravati Rare Diseases Organisation. We strive to provide you with the best products/services, but we understand that there may be occasions where you need to cancel or return an item. Our cancellation and refund policy is designed to make the process straightforward and fair.
                     </p>
                    <br/>
                    <h5 style={{ color: "#2c3e50" }}>Return and Exchange Time Frame:</h5>


                    <p>Thank you for supporting <strong>Amaravati Rare Diseases Organization (ARDO)</strong>. As a nonprofit organization (NGO) dedicated to assisting individuals with rare diseases, we strive to ensure that all resources and contributions are used to serve our mission effectively.</p>
            <br/>
            <h5 style={{ color: "#2c3e50" }}>No Refunds or Cancellations</h5>
            <p>ARDO does not provide refunds or cancellations for any registrations, including:</p>
            <ul>
                <li>Patient Registrations</li>
                <li>Registrations for Wheelchair Donations</li>
            </ul>
            <p>All registrations are considered final and are strictly bound to the rules and policies of the organization.</p>
            <br/>
            <h5 style={{ color: "#2c3e50" }}>Policy Terms</h5>
            <ul>
                <li><strong>No Refunds or Cancellations:</strong> ARDO operates under a strict no-refund and no-cancellation policy for all registrations.</li>
                <li><strong>Binding Agreement:</strong> By completing a registration with ARDO, you agree to these terms and acknowledge that all registrations are final.</li>
                <li><strong>NGO Rules:</strong> All registrations are subject to the rules and policies set forth by ARDO to ensure fairness, transparency, and alignment with our mission.</li>
            </ul>
            <br/>
            <h5 style={{ color: "#2c3e50" }}>Policy Changes</h5>
            <p>Amaravati Rare Diseases Organization reserves the right to modify or update this policy at any time. Any changes will be effective immediately upon posting on our website or through other communication channels.</p>
            <br/>
            <h5 style={{ color: "#2c3e50" }}>Contact Information</h5>
            <div style={{ marginTop: "20px" }}>
                <p><strong>Phone Number:</strong> +91 70933 35285</p>
                <p><strong>Email:</strong> <a href="mailto:ardocare.ap@gmail.com">ardocare.ap@gmail.com</a></p>
                <p><strong>Website:</strong> <a href="https://ardo.org.in" target="_blank" rel="noopener noreferrer">ardo.org.in</a></p>
                <p><strong>Office Address:</strong><br />
                Vijayawada, Andhra Pradesh - 520002</p>
                <p><strong>Home Address:</strong><br />
                Plot No. 2/125B, Nuzvid City,
                Krishna District, Vijayawada, Andhra Pradesh - 521211</p>
            </div>
            </div>
                <FooterOne />
            </>
        );
    }
}

export default Privacy;
