import React, { useEffect,useState } from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterOne from '../common/footer/FooterOne';
import { useParams, useRoutes } from 'react-router-dom';


const PaymentPage=()=>{

    const params=useParams();
    const [status, setStatus]=useState("");
    useEffect(()=>{
        setStatus(params.status);
    },[])

    const getPaymentSucess=()=>{
        return(
            <div className='text-center'>
                <h1 className='text-success'>Payment Success</h1>
            </div>
        )

    }


    const getPaymentFailed=()=>{
        return (
            <div className='text-center'>
                <h1  className='text-danger'>Payment Failed</h1>
            </div>
        )
    }
    return (
        <>
            <HeaderOne></HeaderOne>
                <Breadcrumb
                heading="Payment Status"
                currentPage="Payment Status"
                ></Breadcrumb>
                <div className='py-5'> 
                    {params.status=="success"?getPaymentSucess():getPaymentFailed()}
                </div>
               
            <FooterOne></FooterOne>
        </>
    )
}



export default PaymentPage;