import axios from 'axios';

// Create an Axios instance with a base URL
const axiosInstance = axios.create({
  baseURL: 'https://api.ardo.org.in/', // Set your base URL here
  timeout: 10000, // Optional: Set a timeout for requests
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    // Modify the config before the request is sent
    let token=await localStorage.getItem("token");
    if(token){
      config.headers['Authorization'] = token;
    } // Example: Add an auth token
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Handle the response data
    console.log('Response:', response); // Debugging: Log the response
    return response;
  },
  (error) => {
    // Handle response error
    console.error('Response Error:', error); // Debugging: Log the error
    return Promise.reject(error);
  }
);


export default axiosInstance;
