import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import FooterOne from "../common/footer/FooterOne"
import HeaderOne from "../common/header/HeaderOne"
import RestServices from "../services/rest.service";
import EndpointConstants from "../constants/Endpoint.constants";
import Breadcrumb from '../common/breadcrumb/Breadcrumb';




const Details=(props)=>{

    const params=useParams();
    const id=params.id;
    const [details, setDetails]=useState(null);
    const restService=new RestServices();
    useEffect(()=>{
        getDetails(params.id);
    },[]);


    const getDetails=(id)=>{
        let url=EndpointConstants.GET_EVENT_BY_ID.replace("{id}",id);
        restService.doGet(url).then(({data})=>{
            console.log("validate and check",data)
            setDetails(data);
            doChanges()
        }).catch((err)=>{
            console.log(err);
        })
    }
    const doChanges=()=>{
		const $ = window.$;

		if ($(".img-popup").length) {
			var groups = {};
			$(".img-popup").each(function () {
				var id = parseInt($(this).attr("data-group"), 10);

				if (!groups[id]) {
					groups[id] = [];
				}

				groups[id].push(this);
			});

			$.each(groups, function () {
				$(this).magnificPopup({
					type: "image",
					closeOnContentClick: true,
					closeBtnInside: false,
					gallery: {
						enabled: true
					}
				});
			});
		}
	}
    const getPage=()=>{
        if(details){
            return (
                <>
                    <Breadcrumb
                        heading={details.eventName}
                        currentPage={details.eventName}
                    ></Breadcrumb>

                    <div className="container py-2" >
                    <br/>
                    <p dangerouslySetInnerHTML={ {__html: details.description}}></p>
                    <br/>
                   
                    <div className="row">
                       
                       {details.eventImages.map((eventImage)=>{
                        return (
                            <div className="col-md-4 wow fadeInUp" data-wow-delay="100ms">
									<div className="gallery-page__single">
										<div className="gallery-page__img">
											<img src={eventImage.url} alt="" />
											<div className="gallery-page__icon">
												<a className="img-popup" href={eventImage.url}><span className="icon-plus"></span></a>
											</div>
										</div>
									</div>
								</div>
                        )
                       })}
                    </div>
                    </div>
                </>
            )
        }
    }
    return(
        <>
            <HeaderOne></HeaderOne>
            {getPage()}
            <FooterOne></FooterOne>
        </>
    )
}


export default Details;